import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "256",
      cy: "256",
      r: "256",
      style: {"fill":"#ffda44"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M200.349 222.609h309.484C493.47 97.002 386.067 0 256 0a257 257 0 0 0-55.652 6.085zm-66.784-.001V31.127C63.271 69.481 12.95 139.832 2.167 222.609h131.398zm-.001 66.783H2.167c10.783 82.777 61.104 153.128 131.398 191.481zm66.784.001v216.523A257 257 0 0 0 256 512c130.067 0 237.47-97.002 253.833-222.609H200.348z",
      style: {"fill":"#0052b4"}
    }, null, -1)
  ])))
}
export default { render: render }